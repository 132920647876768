@import '../../../../styles/util-styles/aligned';

.moreAboutButton {
  @include position-absolute;
  bottom: 1rem;

  @media #{$mobile-only} {
    @include position-relative;
    @include margin-top-4;
  }
}

.peopleName {
  @include bold;
  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  font-size: 24px;
  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  line-height: 36px;

  @media #{$mobile-only} {
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    font-size: 20px;
  }
}

.peopleBlurb {
  @include bold;
  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  font-size: 16px;
  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  line-height: 24px;

  @media #{$desktop-only} {
    @include margin-top-1;
  }
}

.peopleDescription {
  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  font-size: 16px;
  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  line-height: 24px;
}

.socialIcons {
  @include margin-top-3;
  @include display-flex;
  @include flex-direction-row;
}

.socialIcon {
  @include margin-right-2;
  /* stylelint-disable-next-line unit-disallowed-list */
  width: 18px;
  /* stylelint-disable-next-line unit-disallowed-list */
  height: 18px;
}

.mobileLayout {
  @include display-block;
  @include display-none--wide;
  @include padding-bottom-4;
}

.mobileImage {
  /* stylelint-disable-next-line unit-disallowed-list */
  width: 65px;
  /* stylelint-disable-next-line unit-disallowed-list */
  height: 65px;
  /* stylelint-disable-next-line unit-disallowed-list */
  min-width: 65px;
}

.mobileContainer {
  @include background-white;
  @include padding-all-3-half;
  @include border-radius-2;
}

.wideLayout {
  @include display-none;
  @include display-flex--wide;
  @include flex-direction-row;
  @include align-items-stretch;
  @include padding-bottom-5;
  @include flex-wrap;
  box-sizing: content-box;
  @include margin-horizontal-auto;
  justify-content: left;

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWide {
    @include width-50;
    @include position-relative;
    @include display-flex;
    @include flex-direction-row;
    @include align-content-stretch;
    @include justify-content-space-around;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWide:nth-child(odd) {
    /* stylelint-disable-next-line unit-disallowed-list */
    flex: 0 1 calc(50% - 20px);
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin: 10px;
  }
  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWide:nth-child(even) {
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin: 10px;
    /* stylelint-disable-next-line unit-disallowed-list */
    flex: 0 1 calc(50% - 20px);
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWideThreePerRow {
    @include background-white;
    @include padding-all-3-half;
    @include width-one-thirds;
    @include position-relative;
    @include display-flex;
    @include flex-direction-row;
    @include align-content-stretch;
    @include justify-content-space-around;
    @include border-radius-2;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWideThreePerRow:nth-child(odd) {
    /* stylelint-disable-next-line unit-disallowed-list */
    flex: 0 1 calc(33% - 20px);
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin: 10px;
  }
  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWideThreePerRow:nth-child(even) {
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin: 10px;
    /* stylelint-disable-next-line unit-disallowed-list */
    flex: 0 1 calc(33% - 20px);
  }
  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContentWide {
    @include display-flex;
    @include flex-direction-column;
    @include align-items-stretch;
    @include justify-content-flex-start;

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .peopleContentImage {
      @include margin-bottom-3;
    }

    /* stylelint-disable-next-line unit-disallowed-list */
    min-height: 465px;
  }
}

.socialLinks {
  @include margin-top-3;

  /* stylelint-disable-next-line max-nesting-depth */
  a:link {
    @include display-inline-block;
    /* stylelint-disable-next-line unit-disallowed-list */
    width: 30px;
    /* stylelint-disable-next-line unit-disallowed-list */
    height: 30px;
    border-radius: 100%;
    @include text-align-center;
    /* stylelint-disable-next-line property-disallowed-list */
    font-size: 0;
    @include color-white;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .twitterLink {
    /* stylelint-disable-next-line color-no-hex, property-disallowed-list */
    background-color: #069ff5;
    @include color-white;
    @include margin-right-2;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .linkedInLink {
    /* stylelint-disable-next-line color-no-hex, property-disallowed-list */
    background-color: #0176b7;
    @include color-white;
  }

  /* stylelint-disable-next-line max-nesting-depth */
  svg {
    /* stylelint-disable-next-line unit-disallowed-list */
    height: 26px;
    /* stylelint-disable-next-line unit-disallowed-list */
    width: 26px;
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin-top: 1px;
    /* stylelint-disable-next-line color-no-hex */
    fill: #fff;
  }
}
