@import '../../../styles/util-styles/aligned';

.companyFooterContainerMobile,
.companyFooterContainerMobileSmall {
  @include display-none--wide;
}

.companyFooterContainerMobile {
  height: 276px;
}

.companyFooterContainerMobileSmall {
  min-height: 300px;
  height: auto;
}

.companyFooterContainerDesktop,
.companyFooterContainerDesktopSmall {
  @include display-none;
  @include display-block--wide;
}

.companyFooterContainerDesktop {
  @media #{$breakpoint-wide} {
    height: 371px;
  }
}

.companyFooterContainerDesktopSmall {
  @media #{$breakpoint-wide} {
    min-height: 250px;
    height: auto;
  }
}

.companyFooterOuter,
.companyFooterOuterSmall {
  height: 259px;
  background-size: auto 259px;
  background-repeat: no-repeat;

  @media #{$breakpoint-wide} {
    height: 371px;
    background-size: auto 371px;
  }
}

.companyFooterOuter {
  height: 320px;
  background-position: bottom right;

  @media #{$breakpoint-wide} {
    height: 371px;
    background-position: bottom center;
  }
}

.companyFooterOuterSmall, .companyFooterOuterSmallLeft {
  min-height: 300px;
  height: auto;
  background-repeat: no-repeat;

  @media #{$breakpoint-wide} {
    min-height: 250px;
  }
}

.companyFooterOuterSmall {
  background-position: bottom left;

  @media #{$breakpoint-wide} {
    background-position: 60%;
  }
}

.companyFooterOuterSmallLeft {
  background-position: bottom right;

  @media #{$breakpoint-wide} {
    background-position: bottom left;
  }
}

.companyFooterCentered {
  text-align: center;

  .companyFooterTitle {
    @include padding-top-5;
    @include padding-bottom-4;

    color: #292929;
    font-family:
      Chronicle Display,
      serif;
    padding-left: 4rem;
    padding-right: 4rem;

    @media #{$breakpoint-wide} {
      @include width-two-thirds;
      @include padding-top-5;
      @include padding-bottom-4;
      margin-left: auto;
      margin-right: auto;
    }

    @media #{$mobile-only} {
      padding-bottom: 1rem;
    }
  }

  .companyFooterSmallTitle {
    @include padding-top-5;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.1px;
    margin-bottom: 2rem;
  }

  .companyFooterSubtitle {
    margin-bottom: 2rem;
  }

  .companyFooterButton {
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;

    @media #{$breakpoint-wide} {
      margin-bottom: 4rem;
    }

    @media #{$mobile-only} {
      @include margin-vertical-0;
    }
  }
}
