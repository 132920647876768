@import '../../../styles/util-styles/aligned';

.pressBrowserContainer {
  @include position-relative;
  overflow: hidden;

  &:focus,
  &:focus-within {
    /* stylelint-disable-next-line property-disallowed-list */
    outline: 0;
  }
  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .pressBrowserCarousel {
    /* stylelint-disable-next-line unit-disallowed-list */
    min-height: 400px;
    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .pressBrowserCarouselWrapper {
      /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
      &.pressBrowserCarouselLoading {
        /* stylelint-disable-next-line unit-disallowed-list */
        filter: blur(2px);
        animation-name: slide;
        animation-timing-function: linear;
        animation-duration: 0.3s;
      }
    }
    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .pressBrowserItem {
      flex: 1 0 100%;
      @media #{$very-wide-layout} {
        flex: 1 0 50%;
      }
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  30% {
    opacity: 0;
  }
  40% {
    transform: translateX(-100%);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}
