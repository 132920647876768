@import '../../../styles/front-page.scss';

.quizSection {
  @media #{$mobile-only} {
    background-color: #fff;
  }
}

.alternateQuizSection {
  @media #{$mobile-only} {
    padding: 0px 16px 48px 16px;
    border-radius: 8px;
  }
}

.container {
  @media #{$mobile-only} {
    padding: 48px 16px 88px 16px;
  }
}

.cardContainer {
  padding: 32px 48px 48px 48px;

  @media #{$max-tablet} {
    padding: 48px 32px 32px 32px;
    // min-height: 517px;
    display: flex;
    flex-direction: column;
  }

  @media #{$mobile-only} {
    padding: 48px 16px 32px 16px;
    background-color: #e3faf2;
    border-radius: 8px;
  }
}

.alternativeCardContainer {
  padding: 32px 48px 48px 48px;

  @media #{$max-tablet} {
    padding: 48px 32px 32px 32px;
    // min-height: 517px;
    display: flex;
    flex-direction: column;
  }

  @media #{$mobile-only} {
    padding: 32px 16px 32px 16px;
    background-color: #e3faf2;
    border-radius: 8px;
  }
}

.title {
  font-size: 24px;
  color: #00482f;

  @media #{$max-tablet} {
    font-size: 18px;
  }
}

.subtitle {
  font-size: 18px;
  font-weight: 350;
  margin: 24px 0;
  color: #3d4045;

  @media #{$max-tablet} {
    font-size: 14px;
  }

  @media #{$mobile-only} {
    display: none;
  }
}

.trimSubtitle {
  display: none;
  @media #{$mobile-only} {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #3d4045;
    margin: 16px 0;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  @media #{$max-tablet} {
    align-items: initial;
    gap: 12px;
  }

  @media #{$desktop-only} {
    min-height: 158px;
  }

  @media #{$mobile-only} {
    min-height: 340px;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.buttonsSecondStepContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  @media #{$max-tablet} {
    flex-direction: column;
    gap: 12px;

    .quizButton {
      width: 100%;
      padding: 16px 32px;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      flex-direction: row;
      max-height: 55px;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  @media #{$desktop-only} {
    min-height: 158px;
  }

  @media #{$mobile-only} {
    min-height: 340px;
    flex-direction: column;

    .quizButton {
      flex-direction: column;
      max-height: unset;
      align-items: flex-start;
    }
  }
}

.quizResetIcon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 12px;
  border-radius: 50%;
  background-color: #40ad87;
  box-shadow: 0px 0.8px 1.6px 0px #00482f;
  height: 24px;
  width: 24px;
  padding: 4px;

  &:hover {
    background-color: #008254;
  }

  @media #{$mobile-only} {
    top: 60px;
    right: 30px;
  }
}

.quizButton {
  cursor: pointer;
  padding: 24px;
  color: #008254;
  background-color: #fff;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(100, 102, 106, 0.36);
  border: 1px solid #fff;
  font-weight: 500;
  line-height: 150%;
  font-family: Gotham;
  font-style: normal;
  letter-spacing: 0.7px;
  font-size: 14px;
  &:hover {
    border-color: #008254;
  }
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media #{$max-tablet} {
    width: 284px;
    padding: 16px;
  }

  @media #{$mobile-only} {
    width: 100%;
    padding: 16px 24px;
    align-items: flex-start;
    flex: 1;
    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.gridPattern {
  background-color: #004d38;
  // apply the background grid pattern, dismissing the need for an image
  background-image: linear-gradient(0deg, #006642 1px, transparent 1px),
    linear-gradient(90deg, #006642 1px, transparent 1px);
  background-size: 20px 20px; /* Adjust grid cell size */
  position: absolute;
  height: 50%;
  width: 100%;
  bottom: 0;

  @media #{$max-tablet} {
    display: none;
  }
}

.desktopQuiz {
  margin-top: 16px;
  padding-top: 64px;
  padding-bottom: calc(110px + 16px);
  @media #{$mobile-only} {
    display: none;
  }
  background-color: #006642;
  position: relative;
}

.alternativeDesktopQuiz {
  padding-top: 32px;
  padding-bottom: 64px;
  @media #{$mobile-only} {
    display: none;
  }
  @media (max-width: 1279px) {
    padding-top: 0px;
  }
  background-color: #006642;
  position: relative;
}

.mobileQuiz {
  display: none;
  position: relative;

  @media #{$mobile-only} {
    display: initial;
  }
}

.belugaContainerDesktop {
  position: absolute;
  top: -50px;
  padding-left: 8px;
}

.belugaContainerMobile {
  position: absolute;
  z-index: 1;
  top: -37px;
  left: 20px;
}

.belugaRevertedDesktop {
  position: absolute;
  top: -115px;
  left: calc(100% - 150px);
}

.belugaRevertedMobile {
  position: absolute;
  z-index: 1;
  top: -115px;
  left: calc(100% - 135px);
}
