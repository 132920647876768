@import '@nerdwallet/base-styles/styles.scss';
@import '../../../styles/front-page-header-variables.scss';

.wrapper {
  width: 100%;
  height: 100%;
  margin-top: -30px;
}

.container {
  width: 100%;
  height: 100%;
  padding-top: 64px;
  padding-bottom: 144px;
  @media (max-width: #{$front-page-tablet-breakpoint}) {
    padding-top: 32px;
    padding-bottom: 48px;
  }
}

.heading {
  @include display-flex;
  @include flex-direction-column;
  gap: 16px;
  padding-bottom: 32px;
  max-width: 810px;
  @media (max-width: #{$front-page-tablet-breakpoint}) {
    width: 100%;
  }
}

.socialIcons {
  @include display-flex;
  @include flex-direction-row;
  gap: 12px;
}

.title {
  color: #ffffff;
  font-family: Gotham;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 31.2px */
  letter-spacing: 0.12px;
  @media (max-width: #{$front-page-tablet-breakpoint}) {
    font-size: 20px;
  }
}

.subTitle {
  color: #ffffff;
  /* Text/Text */
  font-family: Gotham;
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: 150%; /* 24px */
  letter-spacing: 0.08px;
}

.socialVideos {
  position: relative;
  padding-top: 24px;
  @include display-flex;
  @include flex-direction-row;
  @include justify-content-center;
  gap: 24px;
  @media (max-width: #{$front-page-tablet-breakpoint}) {
    gap: 12px;
  }
  @media (max-width: 768px) {
    @include flex-direction-column;
    @include align-items-center;
  }
}

.hideOnMobile {
  @media (max-width: 768px) {
    display: none;
  }
}

.hideOnTablet {
  @media (max-width: 1100px) {
    display: none;
  }
}

.hideOnDesktop {
  @media (min-width: 1279px) {
    display: none;
  }
}

.instagramEmbed {
  width: 100%;
  iframe {
    border: none !important;
    border-radius: 20px !important;
  }
  @media (max-width: 768px) {
    width: 320px;
  }
}
