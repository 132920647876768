@import '@nerdwallet/base-styles/styles.scss';
@import '../../../styles/front-page-header-variables.scss';

.container {
  margin-top: -30px;
}

@mixin rowContainer() {
  @include display-flex;
  @include flex-direction-row;
  @include align-items-center;
  gap: 32px;
}

.firstRow {
  @include rowContainer();
  padding-top: 96px;
  padding-bottom: 48px;
  @media (max-width: 768px) {
    @include flex-direction-column-reverse;
    gap: 0px;
    padding-top: 32px;
    padding-bottom: 24px;
  }
  @media (min-width: 769px) and (max-width: 1279px) {
    @include align-items-flex-end;
    padding-top: 32px;
    padding-bottom: 24px;
  }
}

.secondRow {
  @include rowContainer();
  padding-top: 48px;
  padding-bottom: 176px;
  @media (max-width: 768px) {
    @include flex-direction-column;
    gap: 0px;
    padding-top: 24px;
  }
  @media (min-width: 769px) and (max-width: 1279px) {
    @include align-items-flex-end;
    @include flex-direction-row-reverse;
    padding-top: 24px;
  }
}

.innerContainer {
  padding-bottom: 32px;
}

.folderContainer {
  position: relative;
  width: 100%;
  height: 100%;
  @include display-flex;
  @include flex-direction-column;
  @include align-items-center;
  @media (max-width: 768px) {
    margin-top: -12%;
  }
  @media (min-width: 769px) and (max-width: 1279px) {
    width: 50%;
  }
}

.folderContent {
  @include display-flex;
  @include flex-direction-column;
  @include justify-content-flex-end;
  padding: 32px;
  @media (max-width: 768px) {
    padding: 16px 16px 32px 16px;
  }
}

.nwPlusImage {
  padding-bottom: 24px;
  @media (max-width: 768px) {
    padding-bottom: 16px;
  }
}

.folderMainText {
  padding-bottom: 8px;
  color: $green-darkest; // #00482f
  font-family: Gotham;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 31.2px */
  letter-spacing: 0.12px;
  @media (min-width: 769px) and (max-width: 1279px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    letter-spacing: 0.2px;
  }
}

.folderSubtext {
  padding-bottom: 32px;
  color: $neutral-darkest; // #000
  font-family: Gotham;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.08px;
  @media (min-width: 769px) and (max-width: 1279px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.folderCTA {
  width: 100%;
  @media (max-width: 768px) {
    a {
      width: 100%;
    }
  }
}

.nwPlusTerms {
  padding-top: 12px;
  font-family: Gotham;
  font-size: 10px;
  font-style: normal;
  font-weight: 325;
  line-height: 150%; /* 15px */
  letter-spacing: 0.05px;
}

.smartphoneDesktopImage {
  display: none !important;
  width: 100%;
  max-width: 563px;
  max-height: 410px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 12px -12px #40ad87;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1280px) {
    display: flex !important;
  }
}

.smartphoneTabletImage {
  display: none !important;
  width: 50%;
  max-height: 410px;
  border-radius: 8px;
  overflow: hidden;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 769px) and (max-width: 1279px) {
    display: flex !important;
  }
}

.smartphoneMobileImage {
  display: none !important;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    display: flex !important;
  }
}

.boa2025Wrapper {
  width: 100%;
  height: 100%;
  @include display-flex;
  @include flex-direction-column;
  @include align-items-center;
}

.boa2025Background {
  display: none !important;
  width: 100%;
  height: 100%;
  padding-bottom: 4px;
  padding-left: 10px;
  border-radius: 16px;
  background-image: url('https://www.nerdwallet.com/cdn-cgi/image/format=webp,quality=80/cdn/apps/prod/front-page/images/homepage/superbowl/grid-pattern.png');
  background-size: 98%;
  background-repeat: no-repeat;
  background-position: bottom left;
  @media (min-width: 1280px) {
    display: flex !important;
  }
}

.boa2025DesktopImage {
  width: 100%;
  height: 100%;
  max-width: 563px;
  max-height: 410px;
  border-radius: 16px;
  overflow: hidden;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.boa2025TabletImage {
  display: none !important;
  width: 50%;
  max-height: 410px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 769px) and (max-width: 1279px) {
    display: flex !important;
  }
}

.boa2025MobileImage {
  display: none !important;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    display: flex !important;
  }
}

.shimmerApy {
  display: inline-block;
  min-width: 4.25rem;
  max-width: 4.25rem;
  animation: shimmer 3s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right, #e6e6e6 5%, #cccccc 25%, #e6e6e6 35%);
  background-size: 1000px 100%;
  height: 1em;
  vertical-align: text-top;
  border-radius: 0.25rem;

  @media (max-width: 767px) {
    min-width: 3.25rem;
    max-width: 3.25rem;
  }
}

@keyframes shimmer {
  from {
    background-position: -1000px 0;
  }
  to {
    background-position: 1000px 0;
  }
}
