@import '../../styles/util-styles/aligned';

@media #{$mobile-only} {
  .pressLogoDesktop {
    @include display-none;
  }
}

@media #{$breakpoint-wide} {
  .pressLogoMobile {
    @include display-none;
  }
}
