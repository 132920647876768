@import '@nerdwallet/base-styles/styles';

.text {
  @include margin-right-1;
}

.cursor {
  @include border-left-1;
  @include border-neutral-darkest;
  animation: blink 1s steps(1) infinite;
}

@keyframes blink {
  50% {
    @include border-transparent;
  }
}
