@import '../../../styles/util-styles/aligned.scss';

.zigZagContainer {
  @include width-100;

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .zigZagCenteredReverse {
    @include display-flex;
    @include padding-horizontal-4;
    @include padding-vertical-2--wide;
    @include flex-wrap;
    @include flex-direction-column-reverse;
    @include justify-content-center--wide;
    @include flex-direction-row-reverse--wide;
    @include align-items-center--wide;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .zigZagCentered {
    @include display-flex;
    @include padding-horizontal-4;
    @include padding-horizontal-0--wide;
    @include padding-vertical-2--wide;
    @include flex-wrap;
    @include flex-direction-column-reverse;
    @include flex-direction-row--wide;
    @include justify-content-center--wide;
    @include align-items-center--wide;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .zigZagCenteredBottom {
    @include display-flex;
    @include padding-horizontal-4;
    @include flex-wrap;
    @include flex-direction-column;

    @media #{$breakpoint-wide} {
      @include grid;
      @include padding-horizontal-0;
      grid-row-gap: $var-grid-gap--wide;
      /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
      grid-column-gap: $var-grid-gap--wide;
      justify-content: stretch;
      @include align-content-flex-start;
    }
  }
}

.zigZagContent,
.zigZagContentBottom {
  @include width-100;

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .zigZagTextContainer,
  .zigZagTextContainerLeft {
    @include padding-top-4--wide;
    @include padding-bottom-4;
    @include padding-bottom-0--wide;
    @include padding-vertical-0--wide;
    @include text-align-center;
    @include text-align-left--wide;

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .zigZagTitle {
      @include margin-bottom-3;
      @include chronicle-display;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .zigZagTitleBottom {
      @include margin-top-4;
      @include margin-top-0--wide;
      @include margin-bottom-3;
      @include chronicle-display;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .zigZagHeader {
      @include margin-bottom-3;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .zigZagHeader:not(:first-child) {
      @include margin-top-3-half;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .zigZagButton {
      @include margin-top-3;
      @include margin-top-4--wide;
      @include padding-horizontal-4;
    }
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .zigZagTextContainerLeft {
    @include text-align-left;
  }
}

.zigZagContent:nth-child(odd) {
  @media #{$breakpoint-wide} {
    /* stylelint-disable-next-line unit-disallowed-list */
    width: calc(50% - 40px);
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin: 0 20px;
  }
}
.zigZagContent:nth-child(even) {
  @media #{$breakpoint-wide} {
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin: 0 20px;
    /* stylelint-disable-next-line unit-disallowed-list */
    width: calc(50% - 40px);
  }
}

.zigZagContentBottom {
  @include align-self-center;
  @include display-flex;
  flex: 1;

  @media #{$breakpoint-wide} {
    &:nth-child(1) {
      grid-column-start: 2;
      grid-column-end: 8;
      grid-row: 1;
      justify-self: stretch;
    }

    &:nth-child(2) {
      grid-column-start: 8;
      grid-column-end: 12;
      grid-row: 1;
      justify-self: stretch;
    }
  }
}

.zigZagImageMobile {
  @include margin-vertical-4;
  @include display-none--wide;
  @include width-100;
  height: auto;
}

.zigZagImageMobileBottom {
  @include display-none--wide;
  @include width-100;
  height: auto;
}

.zigZagImageDesktop {
  @include display-none;
  @include display-flex--wide;
  @include margin-horizontal-auto--wide;
  @include margin-vertical-4--wide;
  @include align-self-center--wide;

  @media #{$breakpoint-wide} {
    /* stylelint-disable-next-line unit-disallowed-list */
    max-height: 420px;
    /* stylelint-disable-next-line unit-disallowed-list */
    max-width: 344px;
    @include width-100;
    height: auto;
    object-fit: contain;
  }
}

.zigZagImageDesktopBottom {
  @include display-none;
  @include display-flex--wide;
  @include margin-top-4--wide;
  @include margin-bottom-0--wide;
  @include margin-horizontal-auto--wide;
  @include align-self-flex-end--wide;
  @include flex-direction-row--wide;

  @media #{$breakpoint-wide} {
    /* stylelint-disable-next-line unit-disallowed-list */
    max-height: 420px;
    /* stylelint-disable-next-line unit-disallowed-list */
    max-width: 344px;
    @include width-100;
    height: auto;
    object-fit: contain;
  }
}

.zigZagTitleFirstMobileOnly {
  @include display-none--wide;
  @include text-align-center;
  @include chronicle-display;
  @include padding-top-3-half;
}

.zigZagTitleFirstDesktopOnly {
  @include display-none;
  @include display-block--wide;
  @include chronicle-display;
}
