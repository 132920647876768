@import '@nerdwallet/base-styles/styles.scss';

.privacyPolicyContent {
  span {
    white-space: pre-line;
  }
}

.privacyPolicyTitle {
  /* stylelint-disable-next-line property-disallowed-list */
  font-family: #{$font-family-gotham-display};
  @include bold;
}
