@import '@nerdwallet/base-styles/styles.scss';
@import '../../../styles/react-media-queries.scss';
@import '../../../styles/main.scss';

.socialMediaBoxWrapper {
  @include flex-direction-column;
  @include align-items-center;
  /* stylelint-disable-next-line property-disallowed-list */
  margin: auto;
  @include padding-top-5;
  @include padding-bottom-5;
  @include max-width-80;

  @media #{$mobile-only} {
    @include max-width-100;
  }

  @media #{$very-wide-layout} {
    @include flex-direction-row;
    align-items: initial;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .socialMediaYoutubeWrapper {
    @include width-100;
  }

  .socialMediaYoutubeTextWrapper {
    span {
      @include bold;
      @include gotham;
      @include type-2;
    }
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .socialMediaPlayerWrapper {
    @include position-relative;
    /* stylelint-disable-next-line property-disallowed-list */
    padding-bottom: 50%;
    @include margin-vertical-3;
    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .socialMediaPlayerContainer {
      @include position-absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media #{$max-tablet} {
    @include flex-direction-column;
    @include align-items-center;
  }
  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .socialPostWrapper {
    @media #{$very-wide-layout} {
      @include max-width-40;
      @include padding-right-3;
    }
    @media #{$max-tablet} {
      @include width-100;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .socialPostItem {
      @include width-100;
      /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
      &:first-of-type {
        /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
        padding-right: 10px;
      }

      /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
      &:last-of-type {
        /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
        padding-left: 10px;
      }
    }
    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .socialPostItemImage {
      background-position: center;
      background-size: cover;
      @include width-100;
      /* stylelint-disable-next-line property-disallowed-list */
      padding-top: 100%;
      /* stylelint-disable-next-line property-disallowed-list */
      @include position-relative;
      @media #{$very-wide-layout} {
        /* stylelint-disable-next-line unit-disallowed-list */
        width: 165px;
      }
    }
  }
}

.socialNetworkIconItem {
  /* stylelint-disable-next-line unit-disallowed-list */
  height: 48px;
  /* stylelint-disable-next-line unit-disallowed-list */
  width: 48px;
  border-radius: 50%;
  /* stylelint-disable-next-line property-disallowed-list */
  background-color: #{$green};
  @include display-flex;
  @include justify-content-center;
  @include align-items-center;
}
