@import '../../../styles/util-styles/aligned';

.boaContainer {
  @include padding-all-4;
  @include display-flex;
  @include align-items-center;
  @include justify-content-center;
  @include flex-direction-column;
  @include text-align-center;
  @include background-bone;

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .boaTitle {
    @include margin-bottom-3;
    @include bold;
    /* stylelint-disable-next-line property-disallowed-list */
    font-size: 1.3rem;
  }
}
