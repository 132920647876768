@import '../../../styles/util-styles/aligned';

.mobileLayout {
  @include display-block;
  @include display-none--wide;

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .expandableContainer {
    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .mobileImage {
      /* stylelint-disable-next-line unit-disallowed-list */
      width: 65px;
      /* stylelint-disable-next-line unit-disallowed-list */
      height: 65px;
      /* stylelint-disable-next-line unit-disallowed-list */
      min-width: 65px;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    button {
      @include border-transparent;
      @include background-transparent;
      @include padding-all-0;
      @include border-all-0;
      @include width-100;
      @include cursor-pointer;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .expandableHeader {
      flex-grow: 1;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .textFormatting {
      @include margin-bottom-1;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .expanded {
      @include display-block;
      @include padding-bottom-3;
      /* stylelint-disable-next-line declaration-property-value-allowed-list */
      transition: 0.3s;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .collapsed {
      @include display-none;
      /* stylelint-disable-next-line declaration-property-value-allowed-list */
      transition: 0.3s;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .downArrow {
      transform: rotate(-180deg);
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .copyText {
      white-space: pre-line;
    }
  }
}

.wideLayout {  
  @include flex-direction-row;
  @include align-items-stretch;
  flex-wrap: wrap;
  /* stylelint-disable-next-line unit-disallowed-list */
  max-width: 956px;
  box-sizing: content-box;
  @include margin-horizontal-auto;
  justify-content: left;

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWide {
    @include width-50;
    @include display-flex;
    @include flex-direction-row;
    @include align-content-stretch;
    @include justify-content-space-around;
    @include cursor-pointer;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWide:nth-child(odd) {
    /* stylelint-disable-next-line unit-disallowed-list */
    flex: 0 1 calc(50% - 40px);
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin: 20px;
  }
  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWide:nth-child(even) {
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin: 20px;
    /* stylelint-disable-next-line unit-disallowed-list */
    flex: 0 1 calc(50% - 40px);
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWideThreePerRow {
    @include width-one-thirds;
    @include display-flex;
    @include flex-direction-row;
    @include align-content-stretch;
    @include justify-content-space-around;
    @include cursor-pointer;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWideThreePerRow:nth-child(odd) {
    /* stylelint-disable-next-line unit-disallowed-list */
    flex: 0 1 calc(33% - 40px);
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin: 20px;
  }
  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContainerWideThreePerRow:nth-child(even) {
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin: 20px;
    /* stylelint-disable-next-line unit-disallowed-list */
    flex: 0 1 calc(33% - 40px);
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .peopleContentWide {
    @include padding-all-2;
    @include padding-bottom-3;
    @include display-flex;
    @include flex-direction-column;
    @include align-items-stretch;
    @include justify-content-flex-start;

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .peopleContentImage {
      @include margin-bottom-3;
    }
  }
  @media #{$mobile-only} {
    display: none !important; 
  }
  
}

.peopleModal {
  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .modalContainer {
    @include padding-top-0;
    @include padding-horizontal-4;
    @include padding-bottom-4;

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .modalImage {
      /* stylelint-disable-next-line unit-disallowed-list */
      height: 111px;
      /* stylelint-disable-next-line unit-disallowed-list */
      width: 111px;
      /* stylelint-disable-next-line unit-disallowed-list */
      min-width: 111px;
      @include margin-right-3;
      @include margin-bottom-3;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .modalName {
      @include chronicle-display;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .peopleModalInner {
      white-space: pre-line;
    }

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .peopleInfoStack {
      @include margin-top-3;
    }
  }
}

.socialLinks {
  @include margin-top-3;

  /* stylelint-disable-next-line max-nesting-depth */
  a:link {
    @include display-inline-block;
    /* stylelint-disable-next-line unit-disallowed-list */
    width: 30px;
    /* stylelint-disable-next-line unit-disallowed-list */
    height: 30px;
    border-radius: 100%;
    @include text-align-center;
    /* stylelint-disable-next-line property-disallowed-list */
    font-size: 0;
    @include color-white;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .twitterLink {
    /* stylelint-disable-next-line color-no-hex, property-disallowed-list */
    background-color: #069ff5;
    @include color-white;
    @include margin-right-2;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .linkedInLink {
    /* stylelint-disable-next-line color-no-hex, property-disallowed-list */
    background-color: #0176b7;
    @include color-white;
  }

  /* stylelint-disable-next-line max-nesting-depth */
  svg {
    /* stylelint-disable-next-line unit-disallowed-list */
    height: 26px;
    /* stylelint-disable-next-line unit-disallowed-list */
    width: 26px;
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    margin-top: 1px;
    /* stylelint-disable-next-line color-no-hex */
    fill: #fff;
  }
}
