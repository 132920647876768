@import '../../../styles/util-styles/aligned.scss';

.boxTableContainer {
  @media #{$breakpoint-wide} {
    @include grid;
    grid-row-gap: 2.75rem;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    grid-column-gap: $var-grid-gap--wide;
    justify-content: stretch;
    align-content: flex-start;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .boxTableBox,
  .boxTableBoxNarrow {
    @include margin-bottom-3-half;
    @include margin-bottom-0--wide;

    @media #{$breakpoint-wide} {
      @include display-flex;
      @include flex-direction-column;
      @include justify-content-center;
    }
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .boxTableBox {
    @media #{$breakpoint-wide} {
      /* stylelint-disable-next-line selector-max-class */
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5) {
        grid-column-start: 1;
        grid-column-end: 7;
        justify-self: stretch;
      }

      /* stylelint-disable-next-line selector-max-class */
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6) {
        grid-column-start: 7;
        grid-column-end: 13;
        justify-self: stretch;
      }

      /* stylelint-disable-next-line selector-max-class */
      &:nth-child(1),
      &:nth-child(2) {
        grid-row: 1;
      }

      /* stylelint-disable-next-line selector-max-class */
      &:nth-child(3),
      &:nth-child(4) {
        grid-row: 2;
      }

      /* stylelint-disable-next-line selector-max-class */
      &:nth-child(5),
      &:nth-child(6) {
        grid-row: 3;
      }
    }
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .boxTableBoxNarrow {
    @media #{$breakpoint-wide} {
      /* stylelint-disable-next-line selector-max-class */
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5) {
        grid-column-start: 2;
        grid-column-end: 7;
        justify-self: stretch;
      }

      /* stylelint-disable-next-line selector-max-class */
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6) {
        grid-column-start: 7;
        grid-column-end: 12;
        justify-self: stretch;
      }

      /* stylelint-disable-next-line selector-max-class */
      &:nth-child(1),
      &:nth-child(2) {
        grid-row: 1;
      }

      /* stylelint-disable-next-line selector-max-class */
      &:nth-child(3),
      &:nth-child(4) {
        grid-row: 2;
      }

      /* stylelint-disable-next-line selector-max-class */
      &:nth-child(5),
      &:nth-child(6) {
        grid-row: 3;
      }
    }
    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .videoContainer {
      @include padding-bottom-0;
    }
  }
}

.boxTableInner {
  @include height-100;
  @include width-100;

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .boxTableImage {
    @include width-100;
    @include margin-bottom-3;
    @include margin-bottom-4--wide;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .videoContainer {
    /* stylelint-disable-next-line property-disallowed-list */
    position: relative;
    /* stylelint-disable-next-line property-disallowed-list */
    padding-bottom: 56.25%;
    @include margin-bottom-3;
    @include margin-bottom-4--wide;

    /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
    .videoPlayer {
      /* stylelint-disable-next-line property-disallowed-list */
      @include position-absolute;
      top: 0;
      left: 0;
      @include width-100;
      height: 100%;
    }
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .boxTableTitle {
    @include margin-bottom-3;
  }

  /* stylelint-disable-next-line max-nesting-depth, selector-max-class */
  .boxTableSmallCopy,
  .boxTableCopy {
    @include margin-bottom-3;
  }
}

.playButtonCustomIcon {
  @include display-flex;
  @include align-items-center;
  @include justify-content-center;
  @include position-absolute;
  @include cursor-pointer;
  left: 50%;
  top: 50%;
  transform: translate(-3rem, -3rem);
  height: 6rem;
  width: 6rem;
  z-index: 1;
}

.playButtonDefaultIcon {
  @include color-blue;
  @include background-white;
  @include display-flex;
  @include align-items-center;
  @include justify-content-center;
  @include position-absolute;
  @include cursor-pointer;
  left: 50%;
  top: 50%;
  transform: translate(-3rem, -3rem);
  height: 6rem;
  width: 6rem;
  border-radius: 100%;
  opacity: 0.9;
  z-index: 1;
  /* stylelint-disable-next-line property-disallowed-list, unit-disallowed-list */
  box-shadow: 1px 2px 14px -6px rgba(0, 0, 0, 0.5);
  /* stylelint-disable-next-line max-nesting-depth */
  svg {
    height: 3rem;
    width: 3rem;
  }
}
